import ModalBody from 'reactstrap/es/ModalBody';
import Modal from 'reactstrap/es/Modal';
import ModalHeader from 'reactstrap/es/ModalHeader';
import Alert from 'reactstrap/es/Alert';
import { useLocation } from 'react-router';

export function ErrorPage () {
	const location = useLocation();
	const search = new URLSearchParams(location.search);
	return (
		<Modal isOpen centered>
			<ModalHeader>Ошибка</ModalHeader>
			<ModalBody>
				<Alert color="danger">
					{search.get('message')}
				</Alert>

			</ModalBody>
		</Modal>
	);
}
