import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import { onReady } from '@yooink/youtils/es/onReadyPage';
import { App } from './App';
import { PagesContextWithStore } from './PageContentProvider';
import { LocaleManager } from './Utils/Locale/LocaleSingletone';

const t = new Date().getTime();
console.log('js loaded', new Date().getTime() - t);

LocaleManager.register(window.__currentLocale, (key) => window.__locale[key] || key);

onReady(() => console.log('onReady();', new Date().getTime() - t));
loadableReady(() => console.log('loadableReady()', new Date().getTime() - t));

loadableReady(() => {
	const rootEl = document.getElementById('root');
	ReactDOM.hydrate(
		<Router>
			<PagesContextWithStore {...window.store}>
				<App />
			</PagesContextWithStore>
		</Router>,
		rootEl
	);

	if (module.hot) {
		console.log('load hmr', new Date().getTime() - t);
		module.hot.accept('./App', () => {
			const NewApp = require('./App').App;
			ReactDOM.render(
				<Router>
					<PagesContextWithStore {...window.store}>
						<LocaleProvider locale={window.__currentLocale} getString={(key) => window.__locale[key] || key}>
							<NewApp />
						</LocaleProvider>
					</PagesContextWithStore>
				</Router>,
				rootEl
			);
		});
	}
});
