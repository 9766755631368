import Button from 'reactstrap/es/Button';
import { useContext, useState } from 'react';
import Modal from 'reactstrap/es/Modal';
import ModalBody from 'reactstrap/es/ModalBody';
import { ModalFooter } from 'reactstrap';
import Checkbox from '@yooink/youi/es/Checkbox';
import { PagesContext } from '../../PageContentProvider';

export function SSOAuthRequest () {
	const ctx = useContext(PagesContext);
	const [rememberMe, setRemember] = useState(false);
	return (
		<Modal isOpen centered>
			<ModalBody>
				<h1>Авторизация на сайте</h1>
				<p>Вы подтверждаете что хотите авторизироватся на {ctx.sso.siteName} и предоставить свои данные?</p>
				<ul>
					<li>Имя пользователя</li>
				</ul>
				<Checkbox checked={rememberMe} onChange={(e) => setRemember(e.target.checked)}>
					Запомнить меня на этом сайте
				</Checkbox>
			</ModalBody>
			<ModalFooter>
				<Button color="success" onClick={() => window.location = `/api/sso/${ctx.sso.rId}/grant?remember=${rememberMe.toString()}`}>Войти</Button>
				<Button color="danger" onClick={() => window.location = `/api/sso/${ctx.sso.rId}/dismiss`}>Отменить</Button>
			</ModalFooter>
			<br />
		</Modal>
	);
}
