export function NotFoundPage ({ staticContext }) {
	if (staticContext) staticContext.notFound = true;
	return (
		<>
			<br/>
			<br/>
			<h1>404 - Страница не найдена</h1>
		</>
	);
}
