const singleton = Symbol();
const singletonEnforcer = Symbol();

class LocaleManager {
	constructor (enforcer) {
		if (enforcer != singletonEnforcer) throw 'Cannot construct singleton';
	}

	static get instance () {
		if (!this[singleton]) {
			this[singleton] = new LocaleManager(singletonEnforcer);
		}
		return this[singleton];
	}

	locale = 'ru';

	getString = () => {}

	static register (locale, fn) {
		LocaleManager.instance.locale = locale;
		LocaleManager.instance.getString = fn;
	}
}

export {LocaleManager}
