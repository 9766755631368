import { useSetField } from '@yooink/youtils/es/hooks/useSetField';
import FormGroup from 'reactstrap/es/FormGroup';
import Input from 'reactstrap/es/Input';
import Button from 'reactstrap/es/Button';
import { useLocation } from 'react-router';
import { useState } from 'react';
import Label from 'reactstrap/es/Label';
import { AlertModal } from 'reactstrap-alert/build/es';
import { parseApiError } from '@yooink/youtils/es/ErrorHandler';
import axios from 'axios';
import Checkbox from '@yooink/youi/es/Checkbox';
import Modal from 'reactstrap/es/Modal';
import { ModalFooter } from 'reactstrap';
import ModalBody from 'reactstrap/es/ModalBody';

export function AuthPage () {
	const [{ username, password, rememberMe }, setField] = useSetField({});
	const [disabled, setDisabled] = useState(false);
	const location = useLocation();
	async function login () {
		setDisabled(true);
		try {
			const { data } = await axios.put('/api/auth', {
				username,
				password,
				rememberMe
			});
			if (data.status) {
				const queryParams = new URLSearchParams(location.search);
				if (queryParams.has('redirect')) {
					window.location.href = decodeURIComponent(queryParams.get('redirect'));
				}
			} else {
				AlertModal(data.message || 'Неверное имя пользователя или пароль');
			}
		} catch (err) {
			AlertModal(parseApiError(err));
		} finally {
			setDisabled(false);
		}
	}

	return (
		<Modal isOpen centered>
			<ModalBody>
				<FormGroup>
					<Label>Логин</Label>
					<Input
						name="username"
						value={username}
						onChange={(e) => setField('username', e.target.value)}
					/>
					<Label>Пароль</Label>
					<Input
						name="password"
						type="password"
						value={password}
						onChange={(e) => setField('password', e.target.value)}
					/>
					<Checkbox
						checked={rememberMe}
						onChange={(e) => setField('rememberMe', e.target.checked)}
					>Запомнить меня
					</Checkbox>
				</FormGroup>
			</ModalBody>
			<ModalFooter>
				<Button block disabled={!username || !password || disabled} onClick={() => login()}>Войти</Button>
			</ModalFooter>
		</Modal>

	);
}
