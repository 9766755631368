import { createContext, useState } from 'react';
import Axios from 'axios';
import merge from 'lodash/merge';

const PagesContext = createContext({});

function PagesContextWithStore ({ pages: _pages, user: _user, children, ...otherProps }) {
	const [user, setUser] = useState(_user);
	const [pages, setPages] = useState({ ..._pages });
	const [loading, setLoading] = useState(false);

	async function fetchPage (url, { prefetch = false } = {}) {
		if (!pages[url]) return;
		if (pages[url].loaded) return;
		if (!prefetch) setLoading(true);
		try {
			const { data } = await Axios.get(`/api/page${url}`);
			setPages({
				...pages,
				[url]: {
					loaded: true,
					content: data.content,
					seo: data.seo
				}
			});
			return data;
		} finally {
			if (!prefetch) setLoading(false);
		}
	}

	return (
		<PagesContext.Provider value={{
			loading,
			pages,
			fetchPage,
			user,
			setUser,
			...otherProps,
		}}
		>
			{children}
		</PagesContext.Provider>
	);
}

export {
	PagesContext,
	PagesContextWithStore
};
