import { Route, Switch } from 'react-router-dom';

import './styles/main.less';
import 'bootstrap/dist/css/bootstrap.css';
import '@yooink/youi/es/Checkbox/Checkbox.less';

import { useContext } from 'react';
import { NotFoundPage } from './Pages/NotFoundPage';
import { AuthPage } from './Pages/Auth';
import { PagesContext } from './PageContentProvider';
import { SSOAuthRequest } from './Pages/SSOAuthRequest';
import { ErrorPage } from './Pages/ErrorPage';

function MainPage () {
	return null;
}

export function App () {
	const ctx = useContext(PagesContext);
	return (
		<>
			<Switch>
				<Route exact path="/" component={MainPage} />
				<Route path="/auth" component={AuthPage} />
				<Route path="/sso" component={SSOAuthRequest} />
				<Route path="/error" component={ErrorPage} />
				<Route component={NotFoundPage} />
			</Switch>
			{JSON.stringify({ user: ctx.user })}
		</>
	);
}
